<template>
  <div style="padding:0 20px;">
    <h1>多乐用户协议</h1>
    <div class="title">一、重要须知</div>
    <p>1、用户欲访问和使用“多乐”软件（以下简称“多乐”），必须事先认真阅读、充分理解本协议中各条款，包括免除或者限制“多乐”责任的免责条款及对用户的权利限制条款。如用户不同意本协议，您应不使用或主动取消“多乐”提供的服务。若您使用第三方账号登录，也请您务必认真阅读本协议，您的使用行为将被视为对本协议的完全接受。为维护您自身权益，建议您仔细阅读各条款具体表述。</p>
    <p>2、特别提醒：如您是未成年人，请务必在监护人的陪同下阅读本协议，注册使用“多乐”。</p>
    <p>3、协议变更：在用户接受本协议之后，此文本可能因国家政策、产品以及履行本协议的环境发生变化而进行修改，修改后的协议发布在“多乐”所有产品（包含但不限于APP、网站、PC客户端）上。修改后的协议公布七日后即视为“多乐”已通知用户协议修改内容。若用户对修改后的协议有异议的，请立即停止访问、使用“多乐”软件，若用户继续访问或使用，视为对修改后的协议予以认可。</p>
    <p>4、协议补充：“多乐”目前向用户提供丰富的互联网服务，除了文字、图片资讯信息，还包括但限于语音通讯、在线游戏、聊天室等，如果某一特定服务有单独的协议、规则、指引，用户须遵守该单独的协议、规则和指引，用户接受其他的所有服务均应遵守本协议。</p>
    <p>5、若用户是已经注册了“多乐”账号，用户在遵守本协议的同时还需要遵守《用户注册协议》，该《用户注册协议》是本协议的重要组成部分，若两者有不一致的地方，以本协议为准。</p>
    <p>6、如用户的账户长期未登录，“多乐”有权予以进行注销、回收、替换或采取删除该用您账户在“多乐”平台数据库中的任何记录（包括但不限于注册信息、虚拟礼物信息等）等清理措施，相应服务同时终止。“多乐”在对此类账户进行清理前，将以包括但不限于弹窗、网站公告、站内消息、客户端推送信息等方式通知您。</p>
    <div class="title">二、定义 </div>
    <p>多乐：指多乐App客户端。</p>
    <p>多乐规则：包括所有在多乐上已经发布及后续发布的全部规则、解读、实施细则、产品流程说明、公告等内容。 </p>
    <p>房间：指多乐上，用户可自行开设的个人语音虚拟空间。</p>
    <p>用户：下称“您”或“用户”，是指注册、登录、使用多乐的个人或组织，包括但不限于多乐的厅主、游客。 </p>
    <p>厅主：指注册使用多乐，开设个人“房间”的主体。 </p>
    <p>游客：注册、登录、使用多乐，进入其他用户的房间的主体。 </p>
    <div class="title">三、用户使用规则 </div>
    <p>1、用户不得对本软件进行反向工程、反向汇编、反向编译；不得使用第三方插件干扰、修改、破坏本软件；不得利用软件可能存在的bug或规则漏洞谋取私利。</p>
    <p>2、用户不得利用本软件的服务制作、复制、发布、传播、存储含有下列内容的信息：</p>
    <p>（1）反对宪法所确定的基本原则的；</p>
    <p>（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
    <p>（3）损害国家荣誉和利益的；</p>
    <p>（4）煽动民族仇恨、民族歧视，破坏民族团结的；</p>
    <p>（5）破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
    <p>（6）散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
    <p>（7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
    <p>（8）侮辱或者诽谤他人，侵害他人合法权益的；</p>
    <p>（9）含有法律、行政法规禁止的其他内容的。</p>
    <p>3、未经“多乐”授权，用户不得在“多乐”违规发布任何诸如广告、销售商品的商业行为，包括但不限于以下行为：</p>
    <p>（1）发送宣传色情网站、成人用品网站等色情、低俗内容；</p>
    <p>（2）发送宣传假冒伪劣产品或未经授权销售的产品内容；</p>
    <p>（3）发送宣传国家命令禁止或未取得国家许可销售的产品内容；</p>
    <p>（4）发送宣传游戏代挂代练、虚假人气、贩卖虚拟货币等内容刷枪刷钻、刷QB等内容；</p>
    <p>（5）发送组织、宣传网络兼职内容；</p>
    <p>（6）广告形式宣传其他平台、网络游戏链接；</p>
    <p>（7）恶意刷屏、扰乱平台秩序；</p>
    <p>（8）其他违反法律法规或“多乐”平台规则的内容。</p>
    <p>4、用户保证在使用“多乐”时发布、传播的信息的真实性、准确性，同时保证该等信息不侵犯任何第三方的知识产权。</p>
    <p>5、用户保证在使用“多乐”时遵守“多乐”平台发布的其他平台规则。</p>
    <p>6、若“多乐”发现用户有以上行为的，所有的责任由用户承担，同时“多乐”有权根据违规情节，采取包括但不限于以下处罚措施：暂时冻结账号、永久冻结账号、永久封停账号、回收账号、剔除违法所得等。</p>
    <div class="title">四、告知声明</div>
    <p>1、用户须明白，在使用“多乐”服务可能存在有来自他人包括威胁性的、诽谤性的、令人反感的或非法的内容或行为或对他人权利的侵犯（包括知识产权）的匿名或冒名的信息的风险，用户须承担以上风险，“多乐”对服务不作担保，不论是明确的或隐含的，包括所有有关信息真实性、适商性、适于某一特定用途、所有权和非侵权性的默示担保和条件，对因此导致任何因用户不正当或非法使用服务产生的直接、间接、偶然、特殊及后续的损害，不承担任何责任。</p>
    <p>2、用户使用“多乐”服务必须遵守国家有关法律和政策等，维护国家利益，保护国家安全，并遵守本条款，对于用户违法或违反本协议的使用(包括但不限于言论发表、传送等)而引起的一切责任，由用户负全部责任。</p>
    <p>3、“多乐”的服务同大多数因特网产品一样，易受到各种安全问题的困扰，包括但不限于：</p>
    <p>（1）透露详细个人资料，被不法分子利用，造成现实生活中的骚扰；</p>
    <p>（2）哄骗、破译密码；</p>
    <p>（3） 下载安装的其它软件中含有“特洛伊木马”等病毒，威胁到个人计算计上信息和数据的安全，继而威胁对本服务的使用。对于发生上述情况的，用户应当自行承担责任。</p>
    <p>（4）用户理解，互联网技术的不稳定性，可能导致政府政策管制、病毒入侵、黑客攻击、服务器系统崩溃或者其他现今技术无法解决的风险发生可能导致“多乐”服务中断或帐号道具损失，用户对此非人为因素引起的损失“多乐”不承担责任。</p>
    <p>（5）“多乐”不对发布在本网站上的广告的产品效果、宣传、信息准确性负责，用户在接触这些广告时自行判断。</p>
    <p>（6）用户同意运营商向其接收信息的装置或软件包括但不限于固定电话、移动电话、电子邮箱、QQ、微博、微信等发送服务信息和商业性广告。</p>
    <div class="title">五、知识产权</div>
    <p>1、“多乐”的文字、图片、视频、音频、软件等元素，“多乐”服务标志、标识以及专利权，“多乐”对此享有上述知识产权。</p>
    <p>2、用户使用“多乐”服务只能在本协议以及相应的授权许可协议授权的范围使用“多乐”知识产权，未经授权超范围使用的构成对“多乐”的侵权。</p>
    <p>3、用户在使用“多乐”服务时发布上传的文字、图片、视频、软件以及表演等用户原创的信息，此部分信息的知识产权归用户，但用户的发表、上传行为是对“多乐”服务平台的授权，用户确认其发表、上传的信息非独占性、永久性的授权，该授权可转授权。</p>
    <p>4、若“多乐”软件内的信息以及其他用户上传、存储、传播的信息有侵犯的用户或第三人的知识产权的，“多乐”提供投诉通道。</p>
    <div class="title">六、多乐服务及争议处理</div>
    <p style="text-indent: unset;">【服务概况】</p>
    <p>您可以在多乐上享受个人房间，与游客互动、交友、在线语音等服务。多乐将根据经营的实际需要，不时修改或开发新的服务内容，用户能享受的服务范围，以多乐提供的实际功能为准。</p>
    <p style="text-indent: unset;">【收费服务】</p>
    <p>1、“多乐”部分功能或应用涉及付费，用户输入帐号密码的行为视为对收费服务的接受并订阅，用户一旦接受订阅收费服务，需要按多乐公示的收费标准支付费用。</p>
    <p>2、用户在进行消费时，请事先了解消费价格及事项；如您是未成年人，请在监护人的认可后进行消费。</p>
    <p>3、用户付费后获得的权益不得继承或转让给第三人。</p>
    <p style="text-indent: unset;">【争议处理】</p>
    <p>您在多乐使用过程中与其他用户发生争议的，您或其他用户中的任何一方均有权选择以下途径解决： </p>
    <p>（一）与争议相对方自主协商； </p>
    <p>（二）向多乐客服申请协助解决争议； </p>
    <p>（三）向有关行政部门投诉； </p>
    <p>（四）根据与争议相对方达成的仲裁协议（如有）提请仲裁机构仲裁； </p>
    <p>（五）向人民法院提起诉讼。 </p>
    <div class="title">七、隐私保护</div>
    <p>1、请用户注意勿在使用“多乐”服务中透露自己的各类财产帐户、银行卡、信用卡、第三方支付账户及对应密码等重要资料，否则由此带来的任何损失由用户自行承担。</p>
    <p>2、用户在使用“多乐”服务时不可将自认为隐私的信息发表、上传至“多乐”，也不可将该等信息通过“多乐”的服务传播给其他人。若用户的行为引起的隐私泄漏，由用户承担责任。</p>
    <p>3、为实现“多乐”之服务目的，“多乐”在提供服务时可能会搜集用户信息，“多乐”会明确告知用户，通过技术手段所收集的个人信息仅限于您的电话号码、手机通讯录等提供“多乐”使用和优质客户服务所需的信息。</p>
    <p>4、就下列相关事宜的发生，“多乐”不承担任何法律责任：</p>
    <p>（1）“多乐”根据法律规定或相关政府、司法机关的要求提供您的个人信息；</p>
    <p>（2）由于用户将用户密码告知他人或与他人共享注册帐户，由此导致的任何个人信息的泄漏，或其他非因“多乐”原因导致的个人信息的泄漏；</p>
    <p>（3） 任何由于黑客攻击、电脑病毒侵入造成的信息泄漏；</p>
    <p>（4）因不可抗力导致的信息泄漏；</p>
    <div class="title">八、法律责任与免责声明</div>
    <p>1. 用户违反本协议或相关法律、法规、规章的规定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括但不限于合理的律师费等，用户同意赔偿多乐，并使之免受损害。</p>
    <p>2. 如发生下述情形，多乐不承担任何法律责任：</p>
    <p>(1) 依据法律规定或相关政府部门的要求提供您的个人信息；</p>
    <p>(2) 由于您的使用不当而导致任何个人信息的泄露；</p>
    <p>(3) 因技术故障等不可抗事件影响到服务的正常运行的，多乐承诺在第一时间内与相关单位配合，及时处理进行修复，但用户因此而遭受的一切损失，多乐不承担责任；</p>
    <p>(4) 任何由于黑客攻击，电脑病毒的侵入，非法内容信息、骚扰信息的屏蔽，政府管制以及其他任何网络、技术、通信线路、信息安全管理措施等原因造成的服务中断、受阻等不能满足用户要求的情形；</p>
    <p>(5) 用户因第三方如运营商的通讯线路故障、技术问题、网络、电脑故障、系统不稳定及其他因不可抗力造成的损失的情形；</p>
    <p>(6) 使用“多乐”可能存在的来自他人匿名或冒名的含有威胁、诽谤、令人反感或非法内容的信息而招致的风险；</p>
    <p>(7) 用户之间通过“多乐”与其他用户交往，因受误导或欺骗而导致或可能导致的任何心理、生理上的伤害以及经济上的损失；</p>
    <p>(8) 在任何情况下，多乐都不对您或任何第三方因本协议产生的任何间接性、后果性、惩戒性的、偶然的、特殊或惩罚性的损害赔偿承担责任。访问、使用本网站和 “多乐” 所产生的损坏计算机系统或移动通讯设备的风险将由您个人承担；</p>
    <p>(9) 含有法律、行政法规禁止的其它内容的信息；</p>
    <div class="title">九、协议的生效与终止:</div>
    <p>1. 本协议自您接受之日起生效 </p>
    <p>2. 无论是否通知，我们保留在任何时间以任何原因终止本协议的权利，包括出于善意的相信您违反了使用政策或本协议的其他规定。</p>
    <div class="title">十、法律适用、管辖与其他规定</div>
    <p>1、【法律适用】本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国大陆地区法律；如法律无相关规定的，参照商业惯例及/或行业惯例。</p>
    <p>2、【管辖】若用户和多乐服务之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，任何一方均可向多乐经营者注册地提起诉讼。</p>
    <p>3、【协议可分性】本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。 </p>
    <p>4、本协议在多乐软件公示，对用户和网站服务商“多乐”具有法律约束力，用户访问本网站的行为视为对本协议的接受，对用户具有法律约束力。</p>
  </div>
</template>

<script>

export default {
  name: 'userAmt',
  data () {
    return {
      
    }
  },
  created() {
    
  },
  methods: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1{
    font-size: 40px;
    text-align: center;
    margin:20px 0;
}
p{
    font-size: 28px;
    text-indent:56px;
    color: rgba(0,0,0,.8);
    word-break: break-all;
}
.title{
    font-size: 32px;
    color: #000;
    font-weight: bold;
}
</style>
